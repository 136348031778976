import SharedStore from 'shared/stores/shared.store'
import { patchLink } from 'utils/link.utils'

import * as api from '../api/csvLogs.api'

export class CsvLogsStore extends SharedStore {
  storeName = 'CsvLogsStore'
  childApi = api
  // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  downloadCsv = async (id) => {
    window.location.href = patchLink(await api.downloadCsv(id))
  }
}

export default new CsvLogsStore()
